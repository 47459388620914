* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html, body {

  background: rgba(138, 189, 165);

}

.nav {
  width: 100%;
  height: 10%;
  display: flex;
  justify-content: space-between;
  background: rgba(138, 189, 165, 0.487);
}

.nav-links {
  display:flex;
  font-family: 'Poppins';
}

.logo,
.nav-item {
  margin: 2em;
  font-weight:500;
  font-size:1.5vw;
}

.logo .nav-link{

  width: 100%;
  padding: 0.2em;
  padding-left: 0.5em;
  padding-right: 0.5em;
  font-size: 2vw;

  border-radius: 100%;
  background-color: rgb(178, 213, 197);

}

header {
  display:grid;
  place-content: center;
  height: 100vh;
}

h1 {
  font-size: 4rem;
  font-weight: 550;
  font-family: 'Montserrat';
  color: rgb(30, 57, 30);

  width: 50%;
  position:fixed;
  top: 8%;
  left: 100%;
  transform: translate(-50%, -50%);

  text-align: left;

}

p {

  font-family: 'Montserrat';
  color: rgb(30, 57, 30);

}

span {

  font-family: 'Poppins';

}

a {
  text-decoration: none;
  font-weight: 500;
  color: rgb(30, 57, 30);
}

.char {
  transform: translateY(115px);
  transition: transform 0.5s;
}

.slide-in {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgb(30, 57, 30);
  opacity: 100%;
  transform-origin: bottom;
}

.slide-out {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgb(30, 57, 30);
  opacity: 100%;
  transform-origin: top;
}

.typeWriter {

  font-size: 4rem;
  font-weight: 550;
  text-transform: uppercase;
  font-family: 'Montserrat';
  color: rgb(30, 57, 30);

}

@media (max-width: 1650px) {

  h1 {
    left: 92%;
  }

}

@media (max-width: 600px) {

  h1 {
    font-size: 1rem;
    top: 3%;
    font-weight: 550;
  }

  .nav-item {
    margin: 3em;
    font-weight:550;
    font-size:0.5rem;
  }

}


